<template>
  <sub-page
      :title="$t('MonthIncome')"
  >
    <v-sheet class="d-flex justify-space-between wsRoundedLight pa-3 mb-5" :style="`border : 1px solid ${wsBACKGROUND}`">
      <div></div>
      <h1>Total2 : {{ totalPerMonth }} / {{ total }} </h1>

    </v-sheet>

    <ws-data-table
        :headers="headers"
        :items="items"
    >
      <template #item.is_new_avalon="{item}">
        <v-icon class="mr-2" :color="item.is_new_avalon ? wsSUCCESS : wsBACKGROUND">mdi-check-circle-outline</v-icon>
      </template>

      <template #item.active_till="{item}">
        <h5>{{ MONTH_DAY_TIME(item.active_till , false) }}</h5>
      </template>

    </ws-data-table>
  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newClients",
  data() {
    return {
      items : [],
      date : null,
      years : [],
      months : [],
      selectedYear : "2023",
      selectedMonth : 1
    }
  },
  computed : {
    totalPerMonth() {
      let sum = 0
      let items = this.items.map(el => parseInt(el.plan_price))
      items.forEach(el => {
        sum += el
      })
      return sum
    },
    total() {
      let sum = 0
      let items = this.items.map(el => parseInt(el.payment_total))
      items.forEach(el => {
        sum += el
      })
      return sum
    },

    headers() {
      return [

        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Plan') , value : 'plan' },
        { text : this.$t('PlanPrice') , value : 'plan_price' },
        { text : this.$t('ActiveTill') , value : 'active_till' },
        { text : this.$t('Sum') , value : 'payment_total' },
        {  value : 'is_new_avalon' , width : 0 , padding : 0 },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_ACTIVE_BUSINESSES',
    ]),

    async initPage() {
      let result = await this.GET_ACTIVE_BUSINESSES()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  watch : {
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>